@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,600;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

html {
  @apply bg-gray-900;
}

.App {
  text-align: center;
  @apply flex flex-col gap-4;
  @apply h-screen;
  @apply items-center;
  @apply mx-auto;
  @apply max-h-screen;
}
.suggestions_font {
  @apply font-RedHat;
  @apply text-2xl;
  @apply font-bold;
  @apply lg:text-4xl;
}

.desc_text {
  @apply font-RedHat;
  @apply font-bold;
  @apply lg:text-xl;
}

.alt_text {
  @apply font-RedHat;
  @apply font-bold;
  @apply text-white;
  @apply text-base;
  @apply md:text-3xl;
}

.alt_title_text {
  @apply font-RedHat;
  @apply font-bold;
  @apply text-white;
  @apply text-lg;
  @apply md:text-2xl;
}

.contact_desc_text {
  @apply font-RedHat;
  @apply font-bold;
  @apply text-white;
  @apply text-base;
  @apply md:text-xl;
}

.navbar {
  @apply w-screen;
  @apply h-fit;
  @apply mx-auto;
  @apply px-2;
  @apply py-1;
  @apply text-left;
  /* @apply bg-gray-400; */
  @apply opacity-100;
  @apply font-OpenSans;
  @apply font-semibold;
  @apply text-sm;
  @apply md:text-base;
  @apply text-white;
}

.modeSelector {
  @apply bg-transparent;
  @apply font-extrabold;
  @apply text-base;
  @apply md:text-3xl;
  @apply text-yellow-300;
  @apply border-b-2 border-yellow-400;
}

.questionText {
  @apply font-OpenSans;
  @apply underline underline-offset-1;
}

input[type="time" i]::-webkit-calendar-picker-indicator {
  /* @apply bg-slate-300; */
  background-image: url("../public/clock.svg");
  filter: invert(1);
}

.form-input-styles {
  @apply rounded-sm;
  @apply border-2 border-black;
}
.form-submit-button {
  @apply bg-gray-400;
  @apply rounded-md;
  @apply border-2 border-black;
}
.form-label-text {
  @apply text-white;
  @apply font-RedHat;
}

/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  @apply bg-yellow-300;
}
.settings-label-font {
  @apply font-OpenSans;
  /* @apply font-bold; */
  @apply text-2xl;
}
.settings-indicator-on {
  @apply font-bold text-red-500;
}
.settings-indicator-off {
  @apply text-gray-300;
}
form-input-styles:invalid {
  @apply border-2 border-dashed border-red-500;
}
